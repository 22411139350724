export const withdrawalFields = [
    {
        prop: 'id',
        label: '序号',
        width: 80,
    },
    {
        prop: 'withdrawal_number',
        label: '提现单号',
    },
    {
        prop: 'account',
        label: '代理人账号',
    },
    {
        prop: 'account_type',
        label: '账户类型',
    },
    {
        prop: 'payment_account',
        label: '打款账户',
    },
    {
        prop: 'withdrawal_amount',
        label: '提现金额',
    },
    {
        prop: 'add_time',
        label: '提交时间',
    },
    {
        prop: 'status_name',
        label: '打款状态',
    },
    {
        prop: 'interface',
        label: '打款接口信息',
    },
    {
        prop: 'payment_time',
        label: '打款时间',
    },
    {
        prop: 'operate',
        label: '操作',
    },
]


export const withformSchema = [
    {
        field: 'withdrawal_number',
        label: '提现单号',
        component: 'Input',
        style: {
            width: '300px'
        }
    },
    {
        field: 'status',
        label: '打款状态',
        component: 'Select',
        style: {
            width: '300px'
        },
        componentProps: {
            options: [
                {
                    label: '待打款',
                    value: 1
                },
                {
                    label: '已打款',
                    value: 2
                },
                {
                    label: '已退回',
                    value: 3
                },
                {
                    label: '打款失败',
                    value: 4
                }
            ]
        }
        // ratio: 270,
    },
    {
        field: 'timer',
        label: '提交时间',
        component: 'el-date-picker',
        style: {
            width: '410px'
        },
        componentProps: {
            type: "datetimerange",
            startPlaceholder: "开始日期",
            endPlaceholder:"结束日期",
            valueFormat: 'yyyy-MM-dd HH:mm:ss'
        }
        // ratio: 270,
    },
]